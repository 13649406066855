"use client"

import "../utils/extendDayjs"

import { TooltipProvider } from "@deltagreen/react-library"
import { IoProvider } from "@deltagreen/socketio-react"
import Decimal from "decimal.js"
import { LazyMotion } from "framer-motion"
import { Provider as JotaiProvider } from "jotai"
import { PropsWithChildren } from "react"
import superjson from "superjson"

import { TrpcProvider } from "../app/trpc"

superjson.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Decimal(v),
  },
  "decimal.js",
)

export function GlobalProvider(props: PropsWithChildren) {
  return (
    // https://www.framer.com/motion/guide-reduce-bundle-size/
    <JotaiProvider>
      <LazyMotion features={async () => (await import("./framerMotionFeatures")).default} strict>
        <TooltipProvider>
          <TrpcProvider>
            <IoProvider>{props.children}</IoProvider>
          </TrpcProvider>
        </TooltipProvider>
      </LazyMotion>
    </JotaiProvider>
  )
}
