export const tenantIds = {
  deltagreen: "TID_DELTA_GREEN",
  deltagreenTest: "TID_DELTA_GREEN_TEST",
  eon: "TID_EON",
  centropol: "TID_CENTROPOL",
  mnd: "TID_MND",
} as const

export type TenantId = (typeof tenantIds)[keyof typeof tenantIds]

export function isTenantId(value: unknown): value is TenantId {
  return typeof value === "string" && Object.values(tenantIds).includes(value as unknown as TenantId)
}

export const CONSUMPTION_PREDICTIONS_DAYS_NEEDED = 14

export const logErrorCodes = {
  modbusError: "MODBUS_ERROR",
  modbusClientError: "MODBUS_CLIENT_ERROR",
}

export const ELECTRICITY_PRICES = {
  taxElectricity: 28.3,
  feeElectricityBuy: 350,
  feeElectricitySell: 450,
  systemServices: 212.82,
  distributionFeeFallback: 648.62,
  poze: 495,
} satisfies Record<string, number>

export const ELECTRICITY_VAT = 0.21

export const FLEXIBILITY_PRICE_MARGIN_PERCENTAGE = 0.1
export const FLEXIBILITY_PRICE_FLOOR_CZK_PER_MWH = 2000

export const trpcErrorMessages = {
  failedPostalCodeGeocoding: "Failed to geocode postal code.",
}
